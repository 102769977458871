import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { FiGithub, FiGlobe } from "react-icons/fi";
import {
	setFlex,
	setColor,
	setRem,
	setLetterSpacing,
	media,
} from "../styles/styles";

const Project = ({ project }) => {
	return (
		<ProjectWrapper>
			<BackgroundImage
				fluid={project.image.fluid}
				Tag="div"
				className="image"
				style={{ backgroundPosition: "top", height: "250px" }}
			>
				<div className="overlay">
					<p>{project.description.description}</p>
					<div className="links">
						<a href={project.github} target="_blank" rel="noreferrer">
							<FiGithub />
							GitHub
						</a>
						<a href={project.demo} target="_blank" rel="noreferrer">
							<FiGlobe />
							Demo
						</a>
					</div>
				</div>
			</BackgroundImage>
			<h6>{project.name}</h6>
			<p className="stack">{project.stack.split(", ").join(" / ")}</p>
		</ProjectWrapper>
	);
};

const ProjectWrapper = styled.article`
  background: ${setColor.lightGrey};
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  h6 {
    color: ${setColor.primaryDark};
    margin: ${setRem()} ${setRem()} ${setRem(4)};
    text-transform: capitalize;
  }
  p {
    color: ${setColor.darkGrey};
    ${setLetterSpacing(1)};
  }
  .stack {
    font-weight: 400;
    margin: 0 ${setRem()} ${setRem()};
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: ${setColor.lightGrey};
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    ${setFlex({ x: "space-around" })};
    flex-direction: column;
    padding: ${setRem(32)};
    overflow: hidden;
    &:hover {
      opacity: 1;
      p {
        transform: translateY(0);
      }
      .links {
        transform: translateY(0);
      }
    }
    p {
      transform: translateY(-150%);
      transition: all 0.6s ease-in-out;
    }
    .links {
      margin-bottom: ${setRem(10)};
      transform: translateY(200%);
      transition: all 0.6s ease-in-out;
      ${setFlex()};
    }
    a {
      color: ${setColor.primaryDark};
      margin: ${setRem(24)};
      font-weight: 600;
      ${setFlex({ y: "stretch" })};
      &:hover {
        transform: scale(1.1);
      }
      svg {
        margin-right: ${setRem(5)};
      }
    }
  }

  ${media.phone`
    .overlay {
      font-size: ${setRem(14)};
      padding: ${setRem()};
    }
  `}
`;

export default Project;
