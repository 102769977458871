import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { setRem, setColor, setLetterSpacing, media } from "../styles/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/Project"
import bg from "../images/felt.png"

const projects = ({ data }) => {
  const { nodes } = data.projects

  return (
    <Layout>
      <SEO title="projects" />
      <ProjectsWrapper>
        <div className="banner">
          <h1>Projects</h1>
        </div>
        <section>
          {nodes.map(project => (
            <Project project={project} key={project.id} />
          ))}
        </section>
      </ProjectsWrapper>
    </Layout>
  )
}

const ProjectsWrapper = styled.main`
  width: 80%;
  margin: ${setRem(40)} auto;
  .banner {
    padding: ${setRem(8)};
    margin: ${setRem(32)} 0;
    border: 1px solid ${setColor.darkGrey};
    border-radius: 2px;
  }
  h1 {
    padding: ${setRem()};
    display: inline-block;
    ${setLetterSpacing(4)};
    color: ${setColor.darkGrey};
  }
  section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    grid-column-gap: ${setRem(32)};
    grid-row-gap: ${setRem(32)};
  }

  ${media.desktop`
    width: 90%;
    section {
    grid-template-columns: 1fr;
    }
  `}
  ${media.phone`
  section {
    grid-template-columns: 1fr;
    }
  `}
`
export const query = graphql`
  {
    projects: allContentfulPortfolioProject {
      nodes {
        description {
          description
          id
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        name
        stack
        demo
        github
      }
    }
  }
`

export default projects
